<template>
  <a-form-model
    class="ops"
    layout="inline"
    :allow-clear="true"
    :model="form"
  >
    <a-form-model-item>
      <a-select
        :style="{width: '12.8rem'}"
        v-model="form.rank"
        @change="onSearch"
      >
        <a-select-option value="">全部等级</a-select-option>
        <a-select-option
          v-for="rank in ranks"
          :key="rank"
          :value="rank"
        >
          {{ rank }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item>
      <a-input
        v-model="form.parent"
        placeholder="父分类编号"
        @pressEnter="onSearch"
      />
    </a-form-model-item>
    <a-form-model-item>
      <a-input
        v-model="form.q"
        placeholder="编号、名称、拉丁名"
        @pressEnter="onSearch"
      />
    </a-form-model-item>
    <a-form-model-item>
      <a-button
        type="primary"
        icon="search"
        :style="{marginRight: '1rem'}"
        @click="onSearch"
      >
        搜索
      </a-button>
      <a-button
        type="primary"
        icon="plus"
        @click="onCreate"
      >
        新建
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { ranks } from './utils';

export default {
  name: 'Search',
  props: {
    rank: String,
    parent: String,
    q: String
  },
  data() {
    return {
      ranks,
      form: {
        rank: '',
        parent: '',
        q: '',
      },
    }
  },
  methods: {
    onSearch() {
      this.$emit('search', this.form);
    },
    onCreate() {
      this.$emit('create');
    }
  },
  watch: {
    rank(value) {
      this.form.rank = value;
    },
    parent(value) {
      this.form.parent = value;
    },
    q(value) {
      this.form.q = value;
    }
  }
}
</script>

<style scoped>
</style>