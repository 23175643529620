export const columns = [
    {
      title: '编号',
      dataIndex: 'slug',
    },
    {
      title: '名称',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: '拉丁名',
      dataIndex: 'sciname',
      sorter: true,
      scopedSlots: {
        customRender: 'sciname'
      }
    },
    {
      title: '等级',
      dataIndex: 'rank',
      sorter: true,
    },
    {
      title: '父分类',
      dataIndex: 'parent',
      sorter: true,
      scopedSlots: {
        customRender: 'parent'
      }
    },
    {
      title: '子分类数量',
      dataIndex: 'children_count',
      sorter: true,
      scopedSlots: {
        customRender: 'children-count'
      }
    },
    {
      title: '创建时间',
      dataIndex: 'created_time',
      sorter: true,
      scopedSlots: {
        customRender: 'created-time'
      }
    },
    {
      title: '操作',
      scopedSlots: {
        customRender: 'action'
      }
    }
];

export const ranks = [
  '界', '门', '纲', '目', '亚目', '总科', '科', '亚科', '属', '族'
]

export function checkParent (rule, value, callback) {
  if (value == '') {
    callback(new Error('请指定父分类'));
  }
  if (! /^VSL-T\d{8}$/.test(value)) {
    callback(new Error('错误的编号'))
  }
  callback()
}

export const rules = {
  name: [
    { required: true, message: '请输入分类名称', trigger: 'change' }
  ],
  sciname: [
    { required: true, message: '请输入分类拉丁名', trigger: 'change' }
  ],
  rank: [
    { required: true, message: '请指定分类等级', trigger: 'change' }
  ],
  parent: [
    { validator: checkParent, trigger: 'change' }
  ],
};