<template>
  <div id="main-content">
    <Create
      :visible="createVisible"
      @close="onCreateClosed"
      @created="onCreated"
    />
    <Update
      :taxon="taxon"
      :visible="updateVisible"
      @close="onUpdateClosed"
      @updated="onUpdated"
    />
    <a-row :gutter="24">
      <a-col :span="4">
        <DFSAdminNavbar active="分类管理" />
      </a-col>
      <a-col :span="20">
        <div class="admin-content">
          <Search
            :rank="this.queryParams.rank"
            :parent="this.queryParams.parent"
            :q="this.queryParams.q"
            @search="onSearch"
            @create="onCreate"
          />
          <a-table
            :loading="loading"
            :pagination="pagination"
            :columns="columns"
            :data-source="taxons"
            :row-key="taxon => taxon.slug"
            @change="onChange"
          >
            <span slot="sciname" slot-scope="text">
              <i>{{ text }}</i>
            </span>
            <span slot="parent" slot-scope="text">
              <a @click="searchByQ(text)">{{ text }}</a>
            </span>
            <span slot="children-count" slot-scope="text, record">
              <a @click="searchByParent(record.slug)" v-if="text > 0">{{ text }}</a>
              <template v-else>无</template>
            </span>
            <span slot="created-time" slot-scope="text">
              {{ text | moment }}
            </span>
            <span slot="action" slot-scope="record">
              <a @click="onUpdate(record)">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm
                title="您确定要删除该分类吗（注意，如果该分类有子分类或者该分类及其所有子分类下包含物种，这些子分类或者物种的父分类将被清空）？"
                ok-text="确定"
                cancel-text="取消"
                placement="bottom"
                @confirm="onDestroy(record.slug)"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </a-table>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import DFSAdminNavbar from '@/components/DFSAdminNavbar';

import Create from './Create';
import Search from './Search';
import Update from './Update';
import { listTaxons, destroyTaxon } from '@/services/taxon';
import { columns, ranks } from './utils';

export default {
  name: 'Content',
  components: {
    DFSAdminNavbar,
    Create,
    Search,
    Update
  },
  data() {
    return {
      loading: false,
      pagination: {},
      columns: columns,
      ranks: ranks,
      queryParams: {
        rank: '',
        parent: '',
        q: ''
      },
      taxon: {},
      taxons: [],
      createVisible: false,
      updateVisible: false
    };
  },
  methods: {
    fetch(queryParams) {
      const params = { ...queryParams, no_species: true };
      this.loading = true;
      listTaxons(params).then(({ data }) => {
        this.loading = false;
        const pager = { ...this.pagination };
        pager.total = data.data.count;
        this.pagination = pager;
        this.taxons = data.data.results;
      });
    },
    onChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      let queryParams;
      if (Object.keys(sorter).length === 0) {
        queryParams = {...this.queryParams};
      } else {
        const ordering = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
        queryParams = {
          ...this.queryParams,
          ordering
        };
      }
      queryParams.page = pagination.current;
      this.fetch(queryParams);
    },
    onSearch(queryParams) {
      const pager = { ...this.pagination };
      pager.current = 1;
      this.pagination = pager;
      this.queryParams = queryParams;
      this.fetch(queryParams);
    },
    onCreate() {
      this.createVisible = true;
    },
    onCreateClosed() {
      this.createVisible = false;
    },
    onCreated() {
      this.createVisible = false;
      const queryParams = { ...this.queryParams };
      if (this.pagination.current) {
        queryParams.page = this.pagination.current;
      }
      this.fetch(queryParams);
    },
    onUpdate(record) {
      this.taxon = record;
      this.updateVisible = true;
    },
    onUpdateClosed() {
      this.updateVisible = false;
    },
    onUpdated() {
      this.updateVisible = false;
      const queryParams = { ...this.queryParams };
      if (this.pagination.current) {
        queryParams.page = this.pagination.current;
      }
      this.fetch(queryParams);
    },
    onDestroy(slug) {
      destroyTaxon(slug).then(() => {
        this.$message.info('分类' + slug + '已被成功删除。');
        const queryParams = { ...this.queryParams };
        if (this.pagination.current) {
          queryParams.page = this.pagination.current;
        }
        this.fetch(queryParams);
      });
    },
    searchByQ(q) {
      this.queryParams = {
        rank: '',
        parent: '',
        q
      };
      this.onSearch(this.queryParams);
    },
    searchByParent(parent) {
      this.queryParams = {
        rank: '',
        parent,
        q: ''
      };
      this.onSearch(this.queryParams);
    }
  },
  mounted() {
    this.fetch(this.queryParams);
  }
}
</script>


<style lang="stylus" scoped>
#main-content
  padding-top: 2rem

  .ops
    margin-bottom: 1rem
</style>
