<template>
  <div id="main">
    <DFSHeader active="管理" />
    <Content />
    <DFSFooter />
  </div>
</template>

<script>
import DFSHeader from '@/components/DFSHeader';
import DFSFooter from '@/components/DFSFooter';

import Content from './components/Content';

export default {
  name: 'TaxonAdmin',
  components: {
    DFSHeader,
    DFSFooter,
    Content
  }
}
</script>
