<template>
    <a-modal
      title="新建分类"
      ok-text="确定"
      cancel-text="取消"
      :width="800"
      :visible="visible"
      @ok="onConfirm"
      @cancel="onClose"
    >
      <a-form-model
        ref="form"
        :model="taxon"
        :rules="rules"
        :label-col="{span: 4}"
        :wrapper-col="{span: 20}"
      >
        <a-form-model-item label="名称" required prop="name">
          <a-input v-model="taxon.name" />
        </a-form-model-item>
        <a-form-model-item label="拉丁名" required prop="sciname">
          <a-input v-model="taxon.sciname" />
        </a-form-model-item>
        <a-form-model-item label="等级" required prop="rank">
          <a-select v-model="taxon.rank">
            <a-select-option v-for="rank in ranks" :key="rank">
              {{ rank }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="父分类" required prop="parent">
          <a-auto-complete
            v-model="taxon.parent"
            option-label-prop="value"
            :data-source="taxons"
            @search="onSearch"
          >
            <template slot="dataSource">
              <a-select-option
                v-for="taxon in taxons"
                :key="taxon.slug"
                :value="taxon.slug"
              >
                {{taxon.slug}}：<i>{{taxon.sciname}}</i>&nbsp;&nbsp;{{taxon.name}}
              </a-select-option>
            </template>
          </a-auto-complete>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
</template>

<script>
import { listTaxons, createTaxon } from '@/services/taxon';
import { ranks, rules } from './utils';

export default {
  name: 'Create',
  props: {
    visible: Boolean
  },
  data() {
    return {
      ranks,
      rules,
      taxon: {
        name: '',
        sciname: '',
        rank: '',
        parent: ''
      },
      taxons: []
    };
  },
  methods: {
    onSearch(value) {
      listTaxons({ q: value }).then(({ data }) => {
        this.taxons = data.data.results;
      });
    },
    onConfirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          createTaxon(this.taxon).then(() => {
            this.taxon.name = '';
            this.taxon.sciname = '';
            this.taxon.rank = '';
            this.taxon.parent = '';
            this.taxons = [];
            this.$emit('created');
          });
        } else {
          return false;
        }
      });
    },
    onClose() {
      this.$emit('close');
    }
  }
}
</script>
